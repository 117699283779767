import {React,useState} from "react";
import "../../css/style.css";
import "./Header.css";
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import logo2 from "../../images/logo2.png";
import hamImg from '../../images/ham-menu.png'
import awelogo from "../../images/Thundervolt 02.png";
import phonelogo from "../../images/phonewhite.png";
import phonelogoblack from "../../images/phonelogo.png";
import phonecolor from "../../images/pngwing.com.png";
import clockwhite from "../../svg/clockwhite.png";
import locpin from "../../images/locationpin.png";
import closeico from "../../images/close.png";
function Header() {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    }
  return (
    <div>
      <div className="elementor-widget-container" id="header">
        <nav className="panel-menu" id="mobile-menu">
          <ul></ul>
          <div className="mm-navbtn-names">
            <div className="mm-closebtn">Close</div>
            <div className="mm-backbtn">Back</div>
          </div>
        </nav>
        <header id="tt-header">
          <div className="holder-top-desktop d-none d-md-block">
            <div className="container container-lg-fluid">
              <div className="row no-gutters">
                <div className="col-auto">
                  <div className="h-info01">
                    <div className="tt-item">
                      <address>
                        <img src={locpin} alt="" />
                        &nbsp; Plot No. 21, Eros Colony, Near Afsar Trading company, Nari Road, Nagpur - 440 026
                      </address>
                    </div>
                    <div className="tt-item">
                      <img src={clockwhite} alt="" />
                      &nbsp; Mon-Sat 09:00 AM - 06:00 PM
                    </div>
                  </div>
                </div>
                <div className="col-auto ml-auto">
                  <div className="tt-obj">
                    <div className="h-info02">
                      <div className="tt-item">
                        <address>
                          <a href="tel:(+91)7276323778">
                            <img src={phonelogo} alt="" />
                            (+91)7276323778
                          </a>
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /holder-top (desktop) -->
        <!-- holder- --> */}

          <div>
            <div className="tt-holder-wrapper">
              <div className="container container-lg-fluid">
                <div className="tt-holder">
                  <div className="tt-col-logo">
                    {/* <!-- logo --> */}
                    <img className="aweLogo" src={awelogo} alt="Logo" />

                    {/* <!-- /logo --> */}
                  </div>
                  <div className="tt-col-objects tt-col-wide text-center">
                    {/* <!-- desktop-nav --> */}
                    <nav id="tt-nav">
                      <ul id="menu-primary-menu" className="nav navbar-nav">
                        <li
                          id="nav-menu-item-1459"
                          className="main-menu-item  menu-item-even menu-item-depth-0 menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1426 current_page_item"
                        >
                          <a
                            href="#"
                            className="menu-link main-menu-link btnn"
                          >
                            <span className="electric-btn">
                              <span className="text">Home</span>
                            </span>
                          </a>
                        </li>
                        <li
                          id="nav-menu-item-2214"
                          className="main-menu-item  menu-item-even menu-item-depth-0 menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown"
                        >
                          <a
                            href="#aboutus"
                            className="menu-link main-menu-link"
                          >
                            <span className="electric-btn">
                              <span className="text">About Us</span>
                            </span>
                            <span className="ecaret"></span>
                          </a>
                        </li>
                        <li
                          id="nav-menu-item-1525"
                          className="main-menu-item  menu-item-even menu-item-depth-0 menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown"
                        >
                          <a
                            href="#servicesec"
                            className="menu-link main-menu-link"
                          >
                            <span className="electric-btn">
                              <span className="text">Services</span>
                            </span>
                            <span className="ecaret"></span>
                          </a>
                        </li>
                        <li
                          id="nav-menu-item-1480"
                          className="main-menu-item  menu-item-even menu-item-depth-0 menu-item menu-item-type-post_type menu-item-object-page"
                        >
                          <a
                            href="#projects"
                            className="menu-link main-menu-link"
                          >
                            <span className="electric-btn">
                              <span className="text">Projects</span>
                            </span>
                          </a>
                        </li>

                        <li
                          id="nav-menu-item-1505"
                          className="main-menu-item  menu-item-even menu-item-depth-0 menu-item menu-item-type-post_type menu-item-object-page"
                        >
                          <a href="#faq" className="menu-link main-menu-link">
                            <span className="electric-btn">
                              <span className="text">Clients</span>
                            </span>
                          </a>
                        </li>
                        
                        <li>
                          <div className="tt-col__item  d-lg-block rightside">
                            <a
                              href="#"
                              className="tt-btn btn__color01 newbutt"
                              data-toggle="modal"
                              onClick={toggleClass}
                              data-target="#modalMakeAppointment"
                            >
                              Contact us
                            </a>
                          </div>
                        </li>
                      </ul>
                    </nav>
                    {/* <!-- /desktop-nav --> */}
                  </div>
                  <div className="tt-col-objects">
                    <div className="tt-col__item d-block d-lg-none">
                    {/* <a
  href="https://api.whatsapp.com/send?phone=918956559555"  // Replace the phone number with the desired WhatsApp number
  className="tt-btn"
  target="_blank"
  rel="noopener noreferrer"
>
  <img src={phonecolor} alt="" className="phone-icon-mobile" />
</a> */}


                      <button
                        className="ap-hamBurgerClick"
                        onClick={toggleClass}
                      >
                        <img src={hamImg} alt="Hamburger Menu" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="modalMakeAppointment"
            tabindex="-1"
            role="dialog"
            aria-label="myModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content ">
                <div className="modal-body form-default modal-layout-dafault">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    <img src={closeico} alt="" />
                  </button>
                  <div
                    role="form"
                    className="wpcf7"
                    id="wpcf7-f2322-o3"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div className="screen-reader-response">
                      <p
                        role="status"
                        aria-live="polite"
                        aria-atomic="true"
                      ></p>
                      <ul></ul>
                    </div>
                    <form
                      method="POST"
                      action="https://formsubmit.co/aqueela73@gmail.com"
                    >
                      <div className="modal-titleblock">
                        <div className="modal-title">Make an Appointment</div>
                      </div>
                      <div className="form-group">
                        <label for="fname">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="fname"
                          aria-describedby="emailHelp"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="form-group">
                        <label for="email1">Email address</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email1"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          We'll never share your email with anyone else.
                        </small>
                      </div>
                      <div className="form-group">
                        <label for="email1">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="number"
                          id="email1"
                          aria-describedby="emailHelp"
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="message"
                          placeholder="Your Message"
                        />
                      </div>
                      <div className="form-check">
                        {/* <input type="checkbox" className="form-check-input" id="exampleCheck1"/> */}
                        {/* <label className="form-check-label" for="exampleCheck1">Accept Terms & Condition</label> */}
                      </div>
                      <button type="submit" className="tt-btn btn__color01">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dis">
            <nav
              className={
                isActive ? "ap-navMenu floatRgt open" : "ap-navMenu floatRgt"
              }
            >
              {/* Menu links updated 28-09-2022 */}
              <ul className="ap-navigation col-100 floatLft">
                <li className="inlineBlk">
                  <a
                    onClick={toggleClass}
                    href="#abbb"
                    className="primary-dark fonttt"
                  >
                    About us
                  </a>
                </li>
                <li className="inlineBlk">
                  <a
                    onClick={toggleClass}
                    href="#servicesec"
                    className="primary-dark fonttt"
                  >
                    Services
                  </a>
                </li>
                <li className="inlineBlk">
                  <a
                    onClick={toggleClass}
                    href="#projects"
                    className="primary-dark fonttt"
                  >
                    Projects
                  </a>
                </li>
                <li className="inlineBlk">
                  <a
                    onClick={toggleClass}
                    href="#clients"
                    className="primary-dark fonttt"
                  >
                    Clients
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
