import React from 'react'
import awelogo from "../../images/Thundervolt 01.png";
import "../../css/style.css";
import phonecolor from "../../images/phonecolor.png";
import clockwhite from "../../svg/clockwhite.png";
import locpin from "../../images/locationpin.png";

function Footer() {
    const openGoogleMaps = () => {
        // Replace the coordinates or address with your desired location
        const destination = "onlight solution Plot no 21, near Afsar Treding company, eros colony, Dixit Nagar, Nagpur, Maharashtra 440026";
    
        // Construct the Google Maps URL
        const mapsUrl = `https://www.google.com/maps/dir//${encodeURIComponent(destination)}`;
    
        // Open the Google Maps URL in a new tab or window
        window.open(mapsUrl, '_blank');
      };
  return (
    <div>
        <footer id="tt-footer" style={{textAlign:'center',position:'relative'}}>
            <div className="footer-wrapper">
                <div className="container container-lg-fluid container-lg__no-gutters">
                    <div role="form" className="wpcf7" id="wpcf7-f2336-o2" lang="en-US" dir="ltr">
                        <div className="screen-reader-response">
                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                            <ul></ul>
                        </div>
                        <form action="/electrician-v2/#wpcf7-f2336-o2" method="post" className="wpcf7-form init"
                            novalidate="novalidate" data-status="init">
                            {/* <div style="display: none;">
                                <input type="hidden" name="_wpcf7" value="2336" />
                                <input type="hidden" name="_wpcf7_version" value="5.6.3" />
                                <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f2336-o2" />
                                <input type="hidden" name="_wpcf7_container_post" value="0" />
                                <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                            </div> */}
                            {/* <div className="f-form">
                                <div className="f-form__label">Subscribe to Our Newsletter</div>
                                <div className="f-form__input"><span className="wpcf7-form-control-wrap"
                                        data-name="your-email"><input type="email" name="your-email" value="" size="40"
                                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                                            aria-required="true" aria-invalid="false"
                                            placeholder="Your e-mail address" /></span></div>
                                <div className="f-form__btn"><button
                                        className="tt-btn btn__color02 wpcf7-form-control wpcf7-submit" type="submit"><span
                                            className="icon-482948 tt-icon-left"></span> Subscribe &nbsp;<span
                                            className="tt-short-text">now</span></button></div>
                            </div> */}
                            <div className="wpcf7-response-output" aria-hidden="true"></div>
                        </form>
                    </div>
                </div>
                <div className="container container-lg-fluid container-lg__no-gutters">
                    <div className="f-holder row no-gutters">
                        <div className="col-xl-7">
                            <div className="additional-strut">
                                <div className="row">
                                    <div className="col-xl-5">
                                        <div className="f-logo">
                                            <a href="#header"
                                                className="f-logo"><span> <img className="footaweLogo" src={awelogo} alt="Logo" /></span><span className="tt-text"></span></a>
                                           
                                        </div>
                                    </div>
                                    <div className="col-xl-7">
                                        <div className="f-info-text" >
                                            Our experienced electricians are highly trained in all aspects of electrical
                                            service, slep-piping to handover of your Projects
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-5">
                                    <div id="custom_html-1" className="widget_text footer-widget  widget_custom_html">
                                        <div className="textwidget custom-html-widget">
                                            <nav className="f-nav" id="f-nav">
                                                <ul>
                                                    <li className="active"><a
                                                             href="#">Home</a>
                                                    </li>
                                                    
                                                    <li><a
                                                            href="#aboutus">About
                                                            Us</a></li>
                                                    
                                                    <li><a
                                                            href="#servicesec">Services</a>
                                                    </li>
                                                    <li><a
                                                            href="#clients">Clients</a>
                                                    </li>
                                                    
                                                   
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-7">
                                    <div id="custom_html-2" className="widget_text footer-widget2  widget_custom_html">
                                        <div className="textwidget custom-html-widget">
                                            <ul className="f-info-icon">
                                            <li onClick={openGoogleMaps}
                                             style={{
                                                color: 'white', // Set the default color
                                                transition: 'color 0.3s ease', // Add a smooth color transition
                                              }}
                                              onMouseOver={(e) => (e.currentTarget.style.color = '#f47629')}
                                              onMouseOut={(e) => (e.currentTarget.style.color = 'white')}
                                            >
          <img src={locpin} alt="" />&nbsp;
          Plot No. 21, Eros Colony,
          <br />
          Near Afsar Trading company, Nari Road, Nagpur - 440026
        </li>

                                                <li>
                                                    <img src={clockwhite} alt="" />&nbsp;
                                                     Mon-Sat 09:00 AM
                                                    - 06:00 PM</li>
                                                <li><a href="tel:(+91)7276323778">
                                                   <img src={phonecolor} alt="" />&nbsp;
                                                   (+91)7276323778</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="f-copyright row no-gutters">
                        <div className="col-sm-auto"> © 2024 Ansari Electrical Works. All Rights Reserved.</div>
                        <div className="col-sm-auto ml-sm-auto">
                            <ul className="f-social">
                                <li><a href="#" className="icon-twitter-logo-button"></a></li>
                                <li><a href="#" className="icon-facebook-logo-button"></a></li>
                                <li><a href="#" className="icon-instagram-logo"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <a href="#" id="js-backtotop" className="tt-back-to-top">
            <i className="icon-lightning"></i>
        </a>
      
        
    </div>
  )
}

export default Footer