// logoSlider.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mainslide1 from "../../images/Ansari Logo.png";
import mainslide2 from "../../images/philips.png";
import mainslide3 from "../../images/panasonic.png";
import tv1 from "../../images/jio.png";
import tv2 from "../../images/levis";
import vlcc from "../../images/vlcc.avif";
import pooja from "../../images/poojaghr.png";
import mobin from "../../images/mobin.png";
import admark from "../../images/admark.png";
import pra from "../../images/pra.webp";
import fabindia from './fab.svg'
import "../../css/style.css";
import "./sliderLogo.css";
import d from "../../images/4d.png";
import nila from "../../images/nilawar.png";
import smile from "../../images/smile.avif";
import square from "../../images/square.jpeg";
import alfa from "../../images/alfa.png";

function LogoSlider() {
  const logos = [mainslide1, mainslide2, square, mainslide3,tv1,alfa,tv2,vlcc,fabindia,pooja,mobin,admark,pra,d,nila,smile,];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of logos shown at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1100,
    pauseOnHover: false, // Set to false to allow non-stop scrolling even on hover
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="logo-slider-container">
        <br />
        <br />
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default LogoSlider;
