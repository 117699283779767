import React from "react";
import mainslide1 from "../../images/ban1.jpeg";
import mainslide2 from "../../images/mainslide-02.jpg";
import mainslide3 from "../../images/mainslide-03.jpg";
import "../../css/style.css";

import Slider from "react-slick";
import "./slider.css";

function Sliders() {
  const settings = {
   // dots: true,
   arrows: false,
   // fade: true,
   speed: 500,
   autoplay: true,
   autoplaySpeed: 4000,
   slidesToShow: 1,
   SlidesToScroll: 1
  };
  return (
    <div className="bene">
     
        <Slider {...settings}>
          <div className="ap-bannerImg col-100 floatLft" id="abbb">
            <div className="img--holder">
              <img
                src={mainslide1}
                className="ap-deskBanner1"
                alt="Banner Image 1"
              />
            </div>
            <div className="slide-content">
              <div
                className="container text-center js-rotation"
                data-animation="fadeInUpSm"
                data-animation-delay="0s"
              >
                <div className="tt-title-01">keeping you wired</div>
                <div className="tt-title-02">
                  We’re the Current
                  <br />
                  Specialist!
                </div>
              </div>
            </div>
          </div>
          <div className="ap-bannerImg col-100 floatLft textLeft absolute">
            <div className="img--holder">
              <img
                src={mainslide2}
                className="ap-deskBanner1"
                alt="Banner Image 1"
              />
            </div>
            <div className="slide-content">
              <div
                className="container text-center js-rotation"
                data-animation="fadeInUpSm"
                data-animation-delay="0s"
              >
                <div className="tt-title-01">making our clients happier</div>
                <div className="tt-title-02">
                  Best Services for
                  <br />
                  Your Family
                </div>
              </div>
            </div>
          </div>
          <div className="ap-bannerItem1 col-100 floatLft relative">
            <div className="img--holder">
              <img
                src={mainslide3}
                className="ap-deskBanner1"
                alt="Banner Image 1"
              />
            </div>
            <div className="slide-content">
              <div
                className="container text-center js-rotation"
                data-animation="fadeInUpSm"
                data-animation-delay="0s"
              >
                <div className="tt-title-01">we can light everything</div>
                <div className="tt-title-02">
                  Nothing is
                  <br />
                  Impossible for Us
                </div>
              </div>
            </div>
          </div>
        </Slider>


       

      
        
      
    </div>
 
  );
}

export default Sliders;
