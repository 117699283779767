
import './App.css';
import Header from './components/Header/header';

import Sliders from './components/slider/slider';
import Main from './components/mainpage/main';
import Main2 from './components/main2/main2';
import Footer from './components/footer/footer';

function App() {
  return (
    <div className="App">
   <Header/>
   <Sliders/>
   <Main/>
   <Main2/>
   <Footer/>
    </div>
  );
}

export default App;
