import React, { useState } from 'react';
import Slider from 'react-slick';
import "../../css/style.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LogoSlider from '../slider/logoSlider';
import anspdf from '../../pdf/Ansari Electricals Profile Original File.pdf';
import bgmarker2 from '../../images/bg_marker02.png';
import bgmarker from "../../images/bg_marker.png";
import boxbg2 from '../../images/box01-bg02-desktop.jpg';
import blogimg3 from '../../images/blog_img03-370x280.jpg';
import blogimg4 from '../../images/blog_img04-370x280.jpg';
import comser from '../../images/comser48.png';
import tower from '../../images/tower64.png';
import house from '../../images/house.png';
import logo1 from '../../images/logo-01.png';


function Main2() {
 
  return (
    <div>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-09f07bb elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="09f07bb"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8ca30b9"
            data-id="8ca30b9"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-7f38ae7 elementor-widget elementor-widget-elec_price"
                data-id="7f38ae7"
                data-element_type="widget"
                data-widget_type="elec_price.default"
              >
                <div className="elementor-widget-container">
                  {/* <!-- Plans Block --> */}
                  <div className="section-indent">
                    <div className="container">
                      <div className="section-title max-width-01">
                        <div className="section-title__01 ">
                          Save on the Service You Need
                        </div>
                        {/* <div className="section-title__02">
                          Maintenance Plans
                        </div> */}
                        {/* <div className="section-title__03">
                          With an electrical maintenance plan, you won’t find
                          yourself in a panic wondering who to call when you’re
                          having problems with your electrical system.{" "}
                        </div> */}
                      </div>
                      <div className="tt-block-marker">
                        <img
                          decoding="async"
                          className="bg-marker01 block-marker__obj lazyload"
                          src={bgmarker2}
                          alt=""
                        />
                        <div
                          className="tt-layout02-wrapper slick-type01 row"
                          data-slick='{
					"slidesToShow": 3,
					"slidesToScroll": 2,
					"autoplaySpeed": 5000,
					"responsive": [
						{
							"breakpoint": 1230,
							"settings": {
								"slidesToShow": 2,
								"slidesToScroll": 2
							}
						},
						{
							"breakpoint": 767,
							"settings": {
								"slidesToShow": 1,
								"slidesToScroll": 1
							}
						}
					]
					}'
                        >
                          <div className="item col-md-6">
                            <div className="tt-layout02">
                              <div className="tt-layout02__icon">
                                <img src={comser} alt="" />
                              </div>
                              <div className="tt-layout02__title">
                                Commercial Service{" "}
                              </div>
                              <ul className="tt-layout02__list">
                                <li>24 Hour Emergency Service</li>
                                <li>Emergency Lightning system testing , repair and replacement</li>
                                <li>Large ceiling fans</li>
                                <li>Lightning control system</li>
                                <li>Parking lot light repair or LED replacement</li>
                                <li>Remodels</li>
                                <li>Adding new lightning, outlets, timers and USB outlets</li>
                                <li>Building lights installation, repairs or replacement</li>
                                <li>Custom lightning from LED tape lights to festoon lightning</li>
                                <li>Occupancy switches for offices</li>
                                <li>Under cabinet lightning</li>
                              </ul>
                              <hr className="tt-layout02__hr" />
                              {/* <div className="tt-layout02__price">
                                                                $89.00 </div>
                                                            <div className="tt-layout02__link">
                                                                <a href="#" data-toggle="modal"
                                                                    data-target="#modalMakeAppointment"
                                                                    className="tt-link">Order Now<span
                                                                        className="icon-arrowhead"></span></a>
                                                            </div> */}
                            </div>
                          </div>

                          <div className="item col-md-6">
                            <div className="tt-layout02">
                              <div className="tt-layout02__icon">
                                <img src={house} alt="" />
                              </div>
                              <div className="tt-layout02__title">
                                Residential Service{" "}
                              </div>
                              <ul className="tt-layout02__list">
                              <li>24 Hour Emergency Service</li>
                                <li>Installing custom or new lightning</li>
                                <li>Under cabinet lightning</li>
                                <li>Child Proof outlets</li>
                                <li>Adding recessed lightning</li>
                                <li>Converting lightning to LED</li>
                                <li>Installing or adding ceiling fans</li>
                                <li>Installing USB outlets</li>
                                <li>Lightning controlled smart switching</li>
                                <li>Adding giving outlets</li>
                                <li>Wiring Hot tubes</li>
                                <li>Replace old fluorescent tubes with LED Tubes</li>
                                <li>LandScape Lighting</li>
                              </ul>
                              <hr className="tt-layout02__hr" />
                              {/* <div className="tt-layout02__price">
                                                                $12.00 </div>
                                                            <div className="tt-layout02__link">
                                                                <a href="#" data-toggle="modal"
                                                                    data-target="#modalMakeAppointment"
                                                                    className="tt-link">Order Now<span
                                                                        className="icon-arrowhead"></span></a>
                                                            </div> */}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div className="elementor-widget-container">
                  {/* <!-- Counters Block --> */}
                  <div className="section-indent">
                    <div className="section__wrapper">
                      <div className="container container-md-fluid">
                        <div className="tt-info-value row">
                          <div className="tt-col-title col-md-4">
                            <div className="tt-title">
                              <img
                                width="125"
                                height="125"
                                src={bgmarker}
                                className="bg-marker"
                                alt=""
                                decoding="async"
                                loading="lazy"
                                sizes="(max-width: 125px) 100vw, 125px"
                              />
                              <div className="tt-title__01">Our Statistics</div>
                              <div className="tt-title__02">
                                Some Important Facts{" "}
                              </div>
                            </div>
                          </div>
                          <div className="col-auto ml-auto">
                            <div className="tt-item">
                              <div className="tt-value">528+</div>
                              Commercial Projects
                            </div>
                          </div>
                          <div className="col-auto ml-auto">
                            <div className="tt-item">
                              <div className="tt-value">307+</div>
                              Residential Projects
                            </div>
                          </div>
                          <div className="col-auto ml-auto">
                            <div className="tt-item">
                              <div className="tt-value">71+</div>
                              Project Handed over in 2023
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                         </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-49d00b0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="49d00b0"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-62e339c"
            data-id="62e339c"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-c7651bd elementor-widget elementor-widget-electrician_video"
                data-id="c7651bd"
                data-element_type="widget"
                data-widget_type="electrician_video.default"
              >
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-8a9edeb elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="8a9edeb"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-643b783"
            data-id="643b783"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-04e20fd elementor-widget elementor-widget-electrician_blog"
                data-id="04e20fd"
                data-element_type="widget"
                data-widget_type="electrician_blog.default"
              >

              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-fa5930f elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="fa5930f"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d0b0920"
            data-id="d0b0920"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-3e470a2 elementor-widget elementor-widget-electrician-brands"
                data-id="3e470a2"
                data-element_type="widget"
                data-widget_type="electrician-brands.default"
              >
                {/* <div className="elementor-widget-container">
                  <div className="section-indent section_hr">
                      <div className="section-title__02">
                        <h1>
                          PATRON
                        </h1>
                        </div>
                        <br />
                        <br />
                    <div className="container container-md-fluid">
                        
                      <div className="tt-logo-list slick-type01 js-init-carusel-05">
                        <div className="tt-item">
                          <a href="#">
                            <img
                              width="180"
                              height="50"
                              src={logo1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            />{" "}
                          </a>
                        </div>
                        <div className="tt-item">
                          <a href="#">
                            <img
                              width="160"
                              height="50"
                              src={logo1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            />{" "}
                          </a>
                        </div>
                        <div className="tt-item">
                          <a href="#">
                            <img
                              width="165"
                              height="50"
                              src={logo1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            />{" "}
                          </a>
                        </div>
                        <div className="tt-item">
                          <a href="#">
                            <img
                              width="165"
                              height="50"
                              src={logo1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            />{" "}
                          </a>
                        </div>
                        <div className="tt-item">
                          <a href="#">
                            <img
                              width="158"
                              height="50"
                              src={logo1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            />{" "}
                          </a>
                        </div>
                        <div className="tt-item">
                          <a href="#">
                            <img
                              width="154"
                              height="50"
                              src={logo1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                           
                              sizes="(max-width: 154px) 100vw, 154px"
                            />{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="elementor-widget-container" id="clients">
          <div className="section-indent">
            <div className="container container-md-fluid">
              <div className="section-title max-width-01">
                {/* <div className="section-title__01">
                  Your Questions. Our Answers
                </div> */}
                <div className="section-title__02 section-title__01">
                  <b>Our Clients</b>
                </div>
                {/* <div className="section-title__03">
                  You will find answers to all of your most common Electrical
                  Services related questions located here.
                </div> */}
              </div>
              <div className="row tt-faq">
                
              <LogoSlider/>
              </div>
            </div>
          </div>
        </div>
        <br /><br /><br />
        <div className="tt-col__item  d-lg-block">
          <a href={anspdf}
            target='_blank'
          >

            {/* <button
              className="tt-btn btn__color01" >
              <h1
                className="newbut"
              >Know More</h1>
            </button> */}
          </a>
        </div>
      </section>
    </div>
  );
}

export default Main2;
