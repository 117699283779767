import React from "react";
import layout1 from "../../images/indian man.jpeg";
import layout2 from "../../images/layout01-img02.jpg";
import pro1 from "../../images/pro1.jpeg";
import box3img2 from "../../images/box03_img02.jpg";
import box3img1 from "../../images/box03_img01.jpg";
import phonepng from "../../images/phonewhite.png";
import pro2 from "../../images/pro2.jpeg";
import pro3 from "../../images/pro3.jpeg";
import pro4 from "../../images/pro4.jpeg";
import pro5 from "../../images/pro5.jpeg";
import pro6 from "../../images/pro6.jpeg";
import pro7 from "../../images/pro7.jpeg";
import pro8 from "../../images/pro8.jpeg";
import box1bg from "../../images/box01-bg-desktop.jpg";
import pro9 from "../../images/pro9.jpeg";
import pro10 from "../../images/pro10.jpeg";
import pro12 from "../../images/pro12.jpeg";
import pro13 from "../../images/pro13.jpeg";
import pro14 from "../../images/pro14.jpeg";
import pro15 from "../../images/pro15.jpeg";
import pro16 from "../../images/pro16.jpeg";
import pro17 from "../../images/pro17.jpeg";
import pro18 from "../../images/pro18.jpeg";
import pro19 from "../../images/pro1.jpeg";
// import pro20 from "../../images/pro20.jpeg";
import pro11 from "../../images/pro11.jpeg";
import pro21 from "../../images/pro2.jpeg";
import box2img5 from "../../images/box02-img05.jpg";
import img2 from "../../images/srk1.jpg";
import box2img4 from "../../images/box02-img04.jpg";
import box2img1 from "../../images/box02-img01.jpg";
import maskimg1 from "../../images/mask-img01.png";
// import sign from "../../images/img-01.png";
import side1 from "../../images/slideinfo-01.jpg";
import side3 from "../../images/slideinfo-03.jpg";
import side2 from "../../images/slideinfo-02.jpg";
import sidemark from "../../images/slideinfo-marker.png";
import sidein from "../../images/slidein.png";
import bgmarker from "../../images/bg_marker.png";
import "../../css/style.css";
import Slider from "react-slick";
import bgmarker2 from '../../images/bg_marker02.png';



function Main() {
  const settings = {
    slidesToShow: 3,
    autoplaySpeed: 2000,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          autoplay: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 546,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <section>
        <div className="elementor-container elementor-column-gap-no"></div>
      </section>
      <section
        id="navHome"
        className="elementor-section elementor-top-section elementor-element elementor-element-acee1d5 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="acee1d5"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e36c7a2"
            data-id="e36c7a2"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-542e5f5 elementor-widget elementor-widget-electrician_about_two"
                data-id="542e5f5"
                data-element_type="widget"
                data-widget_type="electrician_about_two.default"
              >
                <div className="elementor-widget-container">
                  <div className="section-indent">
                    <div className="container container-lg-fluid">
                      <div className="layout01 layout01__img-more" >
                        <div className="layout01__img">
                          <div className="tt-img-main">
                            <img
                              width="672"
                              height="552"
                              src={layout1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                              sizes="(max-width: 672px) 100vw, 672px"
                            />
                          </div>
                          {/* <div className="tt-img-more left-bottom">
                            <img
                              width="436"
                              height="290"
                              src={layout2}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                              sizes="(max-width: 436px) 100vw, 436px"
                            />
                          </div> */}
                        </div>
                        <div className="layout01__content">
                          <div className="layout01__content-wrapper">
                            <div className="section-title text-left">
                              <div className="section-title__01" id="aboutus">
                                About Us
                              </div>
                              <div className="section-title__02">
                                Outstanding Residential & Commercial Services
                              </div>
                            </div>
                            <p>
                              We take this opportunity to introduce ourselves as an Electrical Consultant/Contractor. We are
                              in this business since 1988 it has been 33 years now. We have undertaken and completed many
                              prestigious projects for Industries, Malls, Hospitals, Banks, Hotels, Commercial and Residential
                              Complexes in Maharashtra, mp, chattisgarh and telangana States.
                              .{" "}
                            </p>

                            <ul className="tt-list01 tt-list-top">
                              <li><h3>Specialization</h3></li>
                              <li>Update Lighting</li>
                              <li>Customized Hanging and wall light</li>
                              <li>Profile light work in any length</li>
                              <li>
                                Technical Light Solution
                              </li>
                              <li>
                                Expert in Residential Bunglow work up to 50000 sq.ft.
                              </li>
                            </ul>
                            <div className="tt-data-info">
                              <div className="tt-item">

                              </div>
                              {/* <div className="tt-item">
                                <img
                                  width="137"
                                  height="47"
                                  src={sign}
                                  className="attachment-full size-full"
                                  alt=""
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-96dbae7 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
        data-id="96dbae7"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
        id="servicesec"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-25e395f"
            data-id="25e395f"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-37c4f70 elementor-widget elementor-widget-elec_work_category"
                data-id="37c4f70"
                data-element_type="widget"
                data-widget_type="elec_work_category.default"
              >

              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-593fd3f elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="593fd3f"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-498094f"
            data-id="498094f"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-f6eaebc elementor-widget elementor-widget-electrician_services_slider"
                data-id="f6eaebc"
                data-element_type="widget"
                data-widget_type="electrician_services_slider.default"
              >
                <div className="elementor-widget-container">
                  <div className="section-indent">
                    <div className="container container-lg-fluid container__p-r">
                      <div className="section-marker section-marker_b-l">
                        <img
                          width="125"
                          height="125"
                          src={bgmarker2}
                          className="attachment-full size-full"
                          alt=""
                          decoding="async"
                          loading="lazy"
                          sizes="(max-width: 125px) 100vw, 125px"
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-3be6208 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="3be6208"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6cdf553"
            data-id="6cdf553"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-910edb8 elementor-widget elementor-widget-ele_banner_1"
                data-id="910edb8"
                data-element_type="widget"
                data-widget_type="ele_banner_1.default"
              >
                <div className="elementor-widget-container">
                  <div className="section-indent">
                    <div
                      className="tt-box01 js-init-bg lazyload"
                      data-bg={box1bg}
                    >
                      <div className="container">
                        <div className="tt-box01__holder">
                          <div className="tt-box01__description">
                            <h4 className="tt-box01__title">
                              Do you{" "}
                              <span className="tt-base-color">Need Help</span>
                              <br /> With Electrical
                              <br /> Maintenance?
                            </h4>
                            <p>
                              Our electrical repair and service options are
                              proudly offered to clients. Give us a call today
                              to schedule a free service estimate!
                            </p>

                            <div className="tt-row-btn">
                              <a
                                className="tt-btn btn__color01"
                                href="tel:(+91)8956559555"
                              >
                                <img src={phonepng} alt="" />
                                Give Us a Call
                              </a>
                              <a
                                className="tt-btn btn__color02"
                                data-toggle="modal"
                                data-target="#modalMakeAppointment"
                                href="#"
                              >
                                Make Appointment
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-091ab54 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="091ab54"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-23f47d8"
            data-id="23f47d8"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-13dd638 elementor-widget elementor-widget-electrician_project"
                data-id="13dd638"
                data-element_type="widget"
                data-widget_type="electrician_project.default"
              >
                <div className="elementor-widget-container">
                  <div className="section-indent">
                    <div className="container container-md-fluid">
                      <div className="section-title max-width-01">
                        {/* <div className="section-title__01">@electricians</div> */}
                        <div id="projects" className="section-title__02">
                          Our Projects
                        </div>
                      </div>

                      <div
                        id="filter-layout"
                        className="row justify-content-center gallery-innerlayout-wrapper js-wrapper-gallery"
                      >
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro1}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro2}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro3}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro4}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro5}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro6}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro7}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro8}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro9}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro10}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro11}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>

                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro13}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro14}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro15}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro16}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro17}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro11}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro19}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                        
                        <div className="col-4 col-md-3 col-custom-item5 residences show all ">
                          <a className="tt-gallery">
                            {/* <div className="gallery__icon"></div> */}
                            <img
                              width="222"
                              height="222"
                              src={pro21}
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            // sizes="(max-width: 222px) 100vw, 222px"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-6798f5a elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="6798f5a"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched"}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e207ce5"
            data-id="e207ce5"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-f16c7c8 elementor-widget elementor-widget-elec_count"
                data-id="f16c7c8"
                data-element_type="widget"
                data-widget_type="elec_count.default"
              >

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ef9575e elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="ef9575e" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7920ebe"
                        data-id="7920ebe" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-da51c69 elementor-widget elementor-widget-electrician_testimonial_two"
                                data-id="da51c69" data-element_type="widget"
                                data-widget_type="electrician_testimonial_two.default">
                                <div className="elementor-widget-container">
                                    <div className="tt-box03 tt-box03__extraindent">
                                        <div className="container container-md-fluid">
                                            <div className="row no-gutters">
                                                <div className="col-md-7">
                                                    <div className="tt-box03__content ">
                                                        <div className="slick-type01 slick-dots-left" data-slick='{
										"slidesToShow": 1,
										"slidesToScroll": 1,
										"autoplaySpeed": 4500
									}'>
                                                            <div className="item">
                                                                <div className="item__row">
                                                                    <div className="tt-item__img">
                                                                        <img width="119" height="119"
                                                                            src={box3img2}
                                                                            className="attachment-full size-full" alt=""
                                                                            decoding="async" loading="lazy"
                                                                            sizes="(max-width: 119px) 100vw, 119px" />
                                                                    </div>
                                                                    <div className="tt-item__title">
                                                                        <div className="section-title text-left">
                                                                            <div className="section-title__01">What Our
                                                                                Clients Say</div>
                                                                            <div className="section-title__02">Professional,
                                                                                Reliable & Cost Effective</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tt-item__content">
                                                                    <blockquote>
                                                                        We've been using your company and from the very
                                                                        beginning found him and his team to be extremely
                                                                        professional and knowledgeable . We wouldn't
                                                                        have any hesitation in recommending their
                                                                        services. <cite>- Teresa and Kevin K.</cite>
                                                                    </blockquote>
                                                                </div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="item__row">
                                                                    <div className="tt-item__img">
                                                                        <img width="119" height="119"
                                                                            src={box3img2}
                                                                            className="attachment-full size-full" alt=""
                                                                            decoding="async" loading="lazy"
                                                                            sizes="(max-width: 119px) 100vw, 119px" />
                                                                    </div>
                                                                    <div className="tt-item__title">
                                                                        <div className="section-title text-left">
                                                                            <div className="section-title__01">What Our
                                                                                Clients Say</div>
                                                                            <div className="section-title__02">Professional,
                                                                                Reliable & Cost Effective</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tt-item__content">
                                                                    <blockquote>
                                                                        We've been using your company and from the very
                                                                        beginning found him and his team to be extremely
                                                                        professional and knowledgeable . We wouldn't
                                                                        have any hesitation in recommending their
                                                                        services. <cite>- Teresa and Kevin K.</cite>
                                                                    </blockquote>
                                                                </div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="item__row">
                                                                    <div className="tt-item__img">
                                                                        <img width="119" height="119"
                                                                            src={box3img2}
                                                                            className="attachment-full size-full" alt=""
                                                                            decoding="async" loading="lazy"
                                                                            sizes="(max-width: 119px) 100vw, 119px" />
                                                                    </div>
                                                                    <div className="tt-item__title">
                                                                        <div className="section-title text-left">
                                                                            <div className="section-title__01">What Our
                                                                                Clients Say</div>
                                                                            <div className="section-title__02">Professional,
                                                                                Reliable & Cost Effective</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tt-item__content">
                                                                    <blockquote>
                                                                        We've been using your company and from the very
                                                                        beginning found him and his team to be extremely
                                                                        professional and knowledgeable . We wouldn't
                                                                        have any hesitation in recommending their
                                                                        services. <cite>- Teresa and Kevin K.</cite>
                                                                    </blockquote>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="tt-box03__img tt-visible-mobile lazyload"
                                                        data-bg={box3img1}>
                                                    </div>
                                                    <div className="tt-box03__extra">
                                                        <div className="tt-title">
                                                            Emergency Service </div>
                                                        <p>
                                                            If this is an emergency outside of normal business hours,
                                                            call us </p>
                                                        <address><a href="tel:8956559555">
                                                          
                                                                (+91)8956559555</a></address>
                                                    </div>
                                                </div>
                                                <div className="tt-box03__img tt-visible-desktop lazyload"
                                                    data-bg={box3img1}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
    </div>
  );
}

export default Main;
